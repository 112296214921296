import React from "react"

import Layout from "../components/layout-page"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" meta={ImageMeta}/>
    <section>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </Layout>
)

export default NotFoundPage
